@import url(/src/index.css);

.info-title {
    margin: 50px auto 2.5rem;
    text-align: center;
    color: var(--primary-font-color);
    font-size: 40px;
}

.info-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 80px;
}

.info-point-group {
  background-image: url(/public/assets/bridge-background.png);
  background-position: 50% 50%;
  background-size: 1000px;
  border-radius: 16px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 10px 10px -2px var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 400px;
  width: 300px;
  padding: 10px 25px 25px 25px;
  margin-bottom: 50px;
}

.info-point-title {
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
}

.info-point-text {
    font-size: 1.1rem;
    text-align: center;
}

.info-video-rectangle {
  display: flex;
  align-items: flex-start;
  min-width: 150px;
  min-height: 150px;
  z-index: 1;
}

.info-lottie {
  height: 100%;
  object-fit: cover;
  width: 100%;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .info-group {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .info-title {
    font-size: 1.8rem;
    margin: 1rem 0;
  }
}