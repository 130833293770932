@import url(/src/index.css);

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq > div:not(.faq-sections) {
  margin: 20px 0px 60px 0px;
  scroll-margin-top: 80px;
}

.faq > div:not(.faq-sections) > h1 {
  margin-bottom: 10px;
  font-size: 36px;
}

.faq-sections {
  display: flex;
  align-items: center;
  justify-content: center;
}

.faq-sections > a {
  font-size: 36px;
  color: var(--primary-font-color);
  cursor: pointer;
  text-decoration: none;
  margin: 1rem 1rem;
  text-align: center;
  padding-right: 2rem;
}

.faq-sections > a.lastSection {
  padding-right: 0;
}

.faq-sections > a:not(.lastSection) {
  border-right: 2px solid #fff;
}

.faq-sections > a:not(.btn-app):hover {
  color: var(--primary-color);
}

.faq-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid var(--primary-color);
  max-width: 1300px;
}
.tab {
  width: 100%;
  color: #ddd;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  background: var(--faq-row-color);
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid var(--primary-color);
  font-size: 20px;
}
    /* Icon */
.tab-label:hover {
  background: lighten(var(--faq-row-color), 30%);
  cursor: pointer;
}
.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all .35s;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: var(--primary-font-color);
  background: var(--faq-row-color);
  transition: all .35s;
  font-size: 18px;
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #555;
  cursor: pointer;
}

.tab-close:hover {
  background: #555;
}

.faq-input:checked + .tab-label {
  background: #555;
}

.faq-input:checked + .tab-label::after  {
  transform: rotate(90deg);
}

.faq-input:checked ~ .tab-content  {
  max-height: 100vh;
  padding: 1em;
  border-bottom: 1px solid var(--primary-color);
}

.tab-label.first-tab {
  border-top: none;
}

.tab-label.last-tab {
  border-bottom: none;
}

@media only screen and (max-width: 768px) {

  .faq-sections > a {
    font-size: 24px;
    margin: 0 0.5rem;
    padding-right: 1rem;
  }

  .faq > div:not(.faq-sections) > h1 {
    font-size: 24px;
  }

  .tab-label {
    font-size: 1rem;
  }

  .tab-content {
    font-size: 0.9rem;
  }
}