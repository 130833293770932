/* css for main hero graphic */

.hero-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  height: min-content;
  margin-bottom: 50px;
}

.hero-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 65%;
}

.hero-right {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: flex-start;
  width: 480px;
}

.landing-image {
  transform: scale(1.1);
  width: 100%;
}

.hero-overlap-group {
  background-image: url('/public/assets/hero-background.png');
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 16px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 10px 10px -2px var(--primary-color);
  z-index: 3;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.hero-intro {
  flex: 3;
}

.hero-tokens {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 120px 20px 40px 20px;
}

.hero-tokens > #hero-scrt {
  margin-bottom: 20px;
  width: 140px;
}

.hero-tokens > #hero-sefi {
  width: 180px;
}

.hero-intro > .bridge-bar {
  margin: 0px 20px;
  height: 40px;
}

.hero-title {
  margin: 0.5rem -5rem 0.6rem 2rem;
  font-size: 2.3rem;
}

.hero-text {
  margin: 0rem 0rem 1.75rem 2rem;
  font-size: 1.1rem;
}

.hero-stats-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin: 0rem -2rem 1.5rem 0.5rem;
}

.hero-stat-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 8rem;
  width: 7.2rem;
  margin: 0px 5px;
}

.hero-stat-group::after {
  content: '';
  display: inline-block;
  background-image: url(/public//assets/stat-background.svg);
  background-position: 50% 50%;
  background-size: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.hero-stat {
  text-align: center;
  font-size: 1rem;
  z-index: 3;
}

.hero-label {
  text-align: center;
  font-size: 0.8rem;
  z-index: 3;
}

.hero-stats-container > div {
  width: 100%;
}

#hero-stats-row-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#hero-stats-row-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -2rem;
  margin-bottom: 0.6rem;
  padding: 0rem 1rem;
}

#hero-stats-row-3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

/* css for video */
.video {
  width: calc(100% - 4.5rem);
  max-width: 500px;
  margin-left: 2.5rem;
  border-radius: 16px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 10px 10px -2px var(--primary-color);
}

/* force video to stay in fixed box */
.video video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

/* css for use app */

.hero-use-app {
  width: calc(100% - 4.5rem);
  height: 35%;
  margin: 2rem 0 2rem 2.5rem;
}

.hero-use-app-group {
  background-image: url(/public/assets/bridge-background.png);
  background-position: 50% 50%;
  background-size: 120%;
  border-radius: 16px;
  border: 2px solid var(--primary-color);
  box-shadow: 0 10px 10px -2px var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.hero-use-app-group > div > img {
  height: 5rem;
  width: 5rem;
  margin: 0.75rem
}

.hero-use-app-text {
  flex: 1;
  letter-spacing: 0;
  line-height: 2rem;
  white-space: nowrap;
  font-size: 1.5rem;
}

/* css for hero cta (social media icon, learn more and buy scrt */

.hero-cta-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

.hero-cta-group > .btn-app {
  margin: 1rem 0.8rem 0;
}

.btn-icon {
  margin-left: 0.5rem;
  height: 0.8rem;
  width: 0.8rem;
}

.hero-cta-group > .social-media-group {
  gap: 0.2rem;
}

@media only screen and (max-width: 1200px) {
  .hero-content {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .hero-left {
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
  }

  .hero-right {
    width: 100%;
  }

  .hero-right > div {
    margin: 1rem auto;
  }

  .video {
    width: auto;
  }

  .background-glow {
    height: 450px;
    width: 450px;
  }

  .hero-use-app {
    width: 500px;
  }

  #hero-stats-row-2 {
    justify-content: space-around;
    padding: 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .hero-use-app-btn {
    min-width: 180px;
  }
}

@media only screen and (max-width: 700px) {
  .hero-cta-group {
    padding-left: 0;
    padding-right: 0;
  }

  .hero-cta-group > .social-media-group {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .hero-overlap-group {
    flex-direction: column;
  }

  .hero-tokens {
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .hero-tokens > #hero-scrt {
    display: none;
  }

  .hero-title {
    margin-right: 0rem;
  }

  .background-glow {
    width: 100%;
    height: 85vw;
  }

  .hero-use-app {
    width: 100%;
  }

  .hero-use-app-text {
    font-size: 1.25rem;
  }

  .hero-cta-group > .btn-app {
    margin-left: 0;
    margin-right: 0;
  }

  .hero-cta-group > .btn-app.secondary {
    margin-right: 1rem;
  }
}

@media only screen and (max-width: 400px) {
  .hero-title {
    text-align: center;
    margin: 0.5rem 1rem;
  }

  .hero-text {
    text-align: center;
    margin: 0.5rem 1rem;
  }

  .hero-stats-container {
    margin: 1rem 0;
  }

  .hero-stat-group {
    height: 7rem;
    width: 6.3rem;
    margin: 0px 5px;
  }

  #hero-stats-row-2 {
    margin-top: -1.5rem;
  }

  .hero-label {
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1275px) {
  #hero-stats-row-2 {
    justify-content: space-around;
  }
}
