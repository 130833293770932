.base-window {
  margin: 130px 3.6vw 40px min(max(15vw, calc(120px + 3.6vw)), calc(150px + 3.6vw));
  display: flex;
  flex-direction: column;
  align-items: center;
}

.base-container {
  max-width: 1300px;
  width: 100%;
}

.base-container.checked > :not(.checked) {
    filter: blur(3px);
}

.base-container .mobile-menu {
    filter: none;
}

.hidden {
  visibility: hidden;
}

.checked.mobile-menu > div {
  transform: scale(1);
  transition-duration: var(--menu-speed);
}

.checked.mobile-menu > div > div {
  opacity: 1;
  transition:  opacity 0.4s ease 0.4s;
}

.mobile-menu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 80px;
  left: 0px;
  overflow: hidden;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.mobile-menu > div {
  background-color: var(--overlay-color);
  border-radius: 50%;
  width: 200vw;
  height: 200vh;
  margin-bottom: 80px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.mobile-menu > div > div {
  max-width: 90vw;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8vw;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.mobile-menu div > div > a {
  font-size: 24px;
  color: var(--primary-font-color);
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.mobile-menu > div > div > a:hover {
  color: var(--primary-color);
}

@media only screen and (max-width: 1040px) {
  .base-window {
    margin-left: calc(120px + 3.6vw);
  }
}

@media only screen and (max-width: 768px) {
  .base-window {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 90px;
  }
}

@media only screen and (min-width: 1500px) {
  .base-window {
    margin: 140px 0px 0px 160px;
  }
}

