@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,900,300,500,600|Work+Sans:500|Noto+Sans:600");

:root {
  --primary-color: #cb9b51;
  --primary-btn-color: #3ba246;
  --secondary-btn-color: #cb9b51;
  --background-color: linear-gradient(#131212, #242424, #131212);
  --header-background-color: linear-gradient(#000000, #242424);
  --faq-row-color: #242424;
  --primary-font-color: #ddd;
  --secondary-font-color: #3ba246;
  --overlay-color: rgba(36, 36, 36, 0.9);
  --menu-speed: 0.75s;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat';
  background-image: var(--background-color);
  color: var(--primary-font-color);
  line-height: 1.6;
}

.header {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid var(--primary-color);
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-image: var(--header-background-color);
  transition: .5s ease;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-left, .header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-left > a {
  display: flex;
  justify-content: center;
}

.header-left > a > img {
  height: 35px;
  margin: 0 1rem;
}

.header-right > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2rem;
}

.header-right > div > img {
  height: 40px;
  width: 40px;
  margin-right: 0.5rem;
}

.pricing {
  font-weight:300;
}

.token {
  font-weight:600;
  margin-right:0.5rem;
}

.header-right > .btn-app {
  font-size: large;
  margin-right: 3rem;
}

.btn-app {
  background-color:#242424;
  color: var(--primary-font-color);
  border: 1px solid var(--primary-btn-color);
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  min-width: 180px;
  transition: all 0.2s ease;
  z-index: 1;
}

.btn-app.solid {
  background-color: var(--primary-btn-color);
}

.btn-app.solid.secondary {
  background-color: var(--secondary-btn-color);
}

.btn-app.secondary {
  border: 1px solid var(--secondary-btn-color);
}

.btn-app.secondary:hover {
  background-color: var(--secondary-btn-color);
}

.btn-app:hover {
  background-color: var(--primary-btn-color);
}

.hamburger-menu {
  cursor: pointer;
  height: 30px;
  margin-left: 2.5rem;
  display: none;
  transition: all 0.4s ease;
}

.hamburger-menu.checked {
  display: inline;
  transform: rotate(180deg);
}

.header.checked > .header-right, .header.checked > .sidenav {
  display: none;
}

.sidenav > a:not(.btn-app):hover {
  color: var(--primary-color);
}

.sidenav {
  height: 100%;
  width: 12vw;
  min-width: 120px;
  max-width: 150px;
  border-right: 1px solid var(--primary-color);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  transition: .5s ease;
  overflow-x: hidden;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidenav > a, .sidenav > h3 {
  font-size: 16px;
  color: var(--primary-font-color);
  text-decoration: none;
  text-align: center;
}

.sidenav > a {
  margin: 1rem 0;
  cursor: pointer;
}

.sidenav > h3 {
  cursor: default;
}

.sidenav > .pools-logo {
  height: 100px;
  width: 100px;
}

.sidenav > .btn-app {
  padding: 0.5rem;
  width: 80%;
  min-width: 0;
}

.footer {
  width: calc(100%-min(max(12vw, 100px), 150px));
  margin-left: min(max(12vw, 100px), 150px);
  margin-top: 80px;
  z-index: 2;
  transition: .5s ease;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-secured-by {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 30px 0;
}

.footer-icon {
  height: 25px;
  width: 20px;
  margin-right: 10px;
}

.footer-logo {
  height: 55px;
  width: 150px;
  margin-left: 10px;
}

.social-media-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.social-media-group > a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.social-media-icon {
  margin: 0 1rem;
  height: 2.5rem;
  width: 2.5rem;
}

.btn-expand:hover {
  transform: scale(1.1);
}

.social-media-group > .btn-expand:hover {
  transform: scale(1.2);
}

.animate-from-left {
  animation: animate-from-left-frames 3s ease 0s 1 normal forwards;
}

@keyframes animate-from-left-frames {
  from {
    opacity: 0;
    transform: scale(1.1) translate(-100px);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(0px);
  }
}

.animate-from-right {
  animation: animate-from-right-frames 3s ease 0s 1 normal forwards;
}

@keyframes animate-from-right-frames {
  from {
    opacity: 0;
    transform: scale(1.1) translate(100px);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(0px);
  }
}

.animate-from-below {
  animation: animate-from-below-frames 3s ease 0s 1 normal forwards;
}

@keyframes animate-from-below-frames {
  from {
    opacity: 0;
    transform: scale(1.1) translate(0, 100px);
  }
  to {
    opacity: 1;
    transform: scale(1) translate(0px);
  }
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media only screen and (max-width: 1050px) {
  .footer {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .hamburger-menu {
    display: inline;
    position: absolute;
  }

  .header-right, .sidenav {
    display: none;
  }

  .header-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: stretch;
  }

  .header-left > a {
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .header-left > a > img {
    margin: 0;
    height: 30px;
  }

  .footer {
    width: 100%;
    margin-left: 0;
  }

  .footer-secured-by > h3 {
    font-size: 1rem;
  }

  .footer-icon {
    height: 18px;
    width: auto;
  }

  .footer-logo {
    height: 40px;
    width: auto;
  }

  .btn-app {
    min-width: 140px;
  }
}

@media only screen and (min-width: 1620px) {
  .sidenav {
    width: 200px;
  }
}